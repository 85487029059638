<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-tabs>
        <b-tab title="New Void">
            <b-card title="Void MR/PR/PO">
              <b-form @submit.prevent="poCheck">
                <b-row>
                  <b-col cols="10">
                    <b-form-group
                      label="MR/PR/PO Number *"
                      label-for="transaction_number"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="transaction_number"
                        placeholder="MR/PR/PO Number"
                        required="required"
                        v-model="TransactionNumber"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="10">
                    <b-form-group
                      label="Reason"
                      label-for="void_reason"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="void_reason"
                        placeholder="Enter the reason"
                        v-model="VoidReason"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col cols="2">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      :disabled="submitButtonDisabled"
                    >
                      Submit
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
        </b-tab>
          <b-tab title="Void MR">
            <b-card title="Void MR List">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <b-col cols="5">
                    <label>Tanggal Awal</label>
                    <b-form-datepicker
                      v-model="startDateFilter"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="5">
                    <label>Tanggal Akhir</label>
                    <b-form-datepicker
                      v-model="endDateFilter"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center justify-content-center">
                    <b-button variant="primary" @click="exportMRVoid()" :disabled="isLoadingExport">
                      <feather-icon icon="DownloadIcon" /> Export
                      <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Show</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>entries</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refFetchVoidMR"
                class="position-relative"
                :items="fetchVoidMR"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                empty-text="No matching records found"
              >
                <!-- Column: Number -->
                <template #cell(material_request_number)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.material_request_number }}</span>
                  </div>
                </template>
                <!-- Column: VoidBy -->
                <template #cell(VoidBy)="data">
                  <b-badge pill class="text-capitalize">
                    {{
                      data.item.deleter
                        ? data.item.deleter.user_fullname
                        : "-"
                    }}
                  </b-badge>
                </template>
                <!-- Column: Reason -->
                <template #cell(Reason)="data">
                  <b-badge pill class="text-capitalize">
                    {{
                      data.item.material_request_deleted_reason
                        ? data.item.material_request_deleted_reason
                        : "-"
                    }}
                  </b-badge>
                </template>
                <!-- Column: VoidTime -->
                <template #cell(material_request_deleted_time)="data">
                  {{
                    data.item.material_request_deleted_time
                      ? dateSimple(
                          data.item.material_request_deleted_time,
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "-"
                  }}
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >Showing {{ dataMetaVoid.from }} to
                      {{ dataMetaVoid.to }} of
                      {{ dataMetaVoid.of }} entries</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-tab>
          <b-tab title="Void PR">
            <b-card title="Void PR List">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <b-col cols="5">
                    <label>Tanggal Awal</label>
                    <b-form-datepicker
                      v-model="startDateFilterPR"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="5">
                    <label>Tanggal Akhir</label>
                    <b-form-datepicker
                      v-model="endDateFilterPR"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center justify-content-center">
                    <b-button variant="primary" @click="exportPRVoid()" :disabled="isLoadingExport">
                      <feather-icon icon="DownloadIcon" /> Export
                      <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Show</label>
                    <v-select
                      v-model="perPagePR"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>entries</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQueryPR"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refFetchVoidPR"
                class="position-relative"
                :items="fetchVoidPR"
                responsive
                :fields="tableColumnsPR"
                primary-key="id"
                show-empty
                :sort-by.sync="sortByPR"
                :sort-desc.sync="isSortDirDescPR"
                empty-text="No matching records found"
              >
                <!-- Column: Number -->
                <template #cell(purchase_request_number)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.purchase_request_number }}</span>
                  </div>
                </template>
                <!-- Column: VoidBy -->
                <template #cell(VoidBy)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">
                      {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
                      }}
                    </span>
                  </div>
                </template>
                <!-- Column: Reason -->
                <template #cell(Reason)="data">
                  <b-badge pill class="text-capitalize">
                    {{
                      data.item.purchase_request_deleted_reason
                        ? data.item.purchase_request_deleted_reason
                        : "-"
                    }}
                  </b-badge>
                </template>
        <!-- Column: VoidTime -->
        <template #cell(purchase_request_deleted_time)="data">
          {{
            data.item.purchase_request_deleted_time?
            dateSimple(
              data.item.purchase_request_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >Showing {{ dataMetaVoidPR.from }} to
                      {{ dataMetaVoidPR.to }} of
                      {{ dataMetaVoidPR.of }} entries</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPagePR"
                      :total-rows="totalItemsPR"
                      :per-page="perPagePR"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-tab>
          <b-tab title="Void PO">
            <b-card title="Void PO List">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <b-col cols="5">
                    <label>Tanggal Awal</label>
                    <b-form-datepicker
                      v-model="startDateFilterPO"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="5">
                    <label>Tanggal Akhir</label>
                    <b-form-datepicker
                      v-model="endDateFilterPO"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center justify-content-center">
                    <b-button variant="primary" @click="exportPOVoid()" :disabled="isLoadingExport">
                      <feather-icon icon="DownloadIcon" /> Export
                      <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Show</label>
                    <v-select
                      v-model="perPagePO"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>entries</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQueryPO"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refFetchVoidPO"
                class="position-relative"
                :items="fetchVoidPO"
                responsive
                :fields="tableColumnsPO"
                primary-key="id"
                show-empty
                :sort-by.sync="sortByPO"
                :sort-desc.sync="isSortDirDescPO"
                empty-text="No matching records found"
              >
                <!-- Column: Number -->
                <template #cell(purchase_order_number)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.purchase_order_number }}</span>
                  </div>
                </template>
                <!-- Column: VoidBy -->
                <template #cell(VoidBy)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">
                      {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
                      }}
                    </span>
                  </div>
                </template>
                <!-- Column: Reason -->
                <template #cell(Reason)="data">
                  <b-badge pill class="text-capitalize">
                    {{
                      data.item.purchase_order_deleted_reason
                        ? data.item.purchase_order_deleted_reason
                        : "-"
                    }}
                  </b-badge>
                </template>
        <!-- Column: VoidTime -->
        <template #cell(purchase_order_deleted_time)="data">
          {{
            data.item.purchase_order_deleted_time?
            dateSimple(
              data.item.purchase_order_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >Showing {{ dataMetaVoidPO.from }} to
                      {{ dataMetaVoidPO.to }} of
                      {{ dataMetaVoidPO.of }} entries</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPagePO"
                      :total-rows="totalItemsPO"
                      :per-page="perPagePO"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-tab>
      </b-tabs>
      </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTabs,
  BTab,
  BFormDatepicker,
  BFormFile,
  VBModal,
  BFormGroup,
  BForm,
} from "bootstrap-vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import vSelect from "vue-select"
import moment from 'moment'
import VoidList from "./VoidList"

const TransactionNumber = ''
const VoidReason = ''
const submitButtonDisabled = false
export default {
  components: {
    BFormGroup,
    vSelect,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    BFormFile,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isLoadingExport: false,
    TransactionNumber,
    VoidReason,
    submitButtonDisabled,
    }
  },
  setup() {
    const {
      fetchVoidMR,
      fetchVoidPR,
      fetchVoidPO,
      tableColumns,
      tableColumnsPR,
      tableColumnsPO,
      perPage,
      currentPage,
      perPagePR,
      currentPagePR,
      dataMetaVoidPR,
      searchQueryPR,
      perPagePO,
      currentPagePO,
      dataMetaVoidPO,
      searchQueryPO,
      totalItems,
      totalItemsPR,
      totalItemsPO,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      sortBy,
      sortByPR,
      sortByPO,
      isSortDirDesc,
      isSortDirDescPR,
      isSortDirDescPO,
      refFetchVoidMR,
      refFetchVoidPR,
      refFetchVoidPO,
      refetchData,
      refetchDataPR,
      resolveMaterialRequestRoleVariant,
      resolveMaterialRequestRoleIcon,
      resolveMaterialRequestStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterPR,
      endDateFilterPR,
      startDateFilterPO,
      endDateFilterPO,
    } = VoidList()
    return {
      fetchVoidMR,
      fetchVoidPR,
      fetchVoidPO,
      tableColumns,
      tableColumnsPR,
      tableColumnsPO,
      perPage,
      currentPage,
      perPagePR,
      currentPagePR,
      dataMetaVoidPR,
      searchQueryPR,
      perPagePO,
      currentPagePO,
      dataMetaVoidPO,
      searchQueryPO,
      totalItems,
      totalItemsPR,
      totalItemsPO,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      sortBy,
      sortByPR,
      sortByPO,
      isSortDirDesc,
      isSortDirDescPR,
      isSortDirDescPO,
      refFetchVoidMR,
      refFetchVoidPR,
      refFetchVoidPO,
      refetchData,
      refetchDataPR,
      resolveMaterialRequestRoleVariant,
      resolveMaterialRequestRoleIcon,
      resolveMaterialRequestStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterPR,
      endDateFilterPR,
      startDateFilterPO,
      endDateFilterPO,
    }
  },
  methods: {
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
  poCheck() {
    this.submitButtonDisabled = true
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        TransactionNumber: this.TransactionNumber,
        VoidReason: this.VoidReason,
        time: moment(),
      }
      const url = `${process.env.VUE_APP_API_URL}transactions/void`
    axios
      .post(url, body, { headers })
      .then(response => {
        this.submitButtonDisabled = false
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invoice Number Invalid!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
  hasPermission(permission) {
    const permissions = this.$cookies.get('userPermissions').split(',')
    return permissions.includes(permission) ? true : false
  },
  },
}
</script>
