import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useVoidList() {
  const userToken = Vue.$cookies.get('userToken')
  const toast = useToast()
  const refFetchVoidMR = ref(null)
  const refFetchVoidPR = ref(null)
  const refFetchVoidPO = ref(null)
  const tableColumns = [
    { key: 'material_request_number', label: 'Number', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'Reason', sortable: false },
    { key: 'material_request_deleted_time', label: 'Void time', sortable: true },
  ]
  const tableColumnsPR = [
    { key: 'purchase_request_number', label: 'Number', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'Reason', sortable: false },
    { key: 'purchase_request_deleted_time', label: 'Void time', sortable: true },
  ]
  const tableColumnsPO = [
    { key: 'purchase_order_number', label: 'Number', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'Reason', sortable: false },
    { key: 'purchase_order_deleted_time', label: 'Void Time', sortable: true },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const perPagePR = ref(10)
  const totalItemsPR = ref(0)
  const currentPagePR = ref(1)
  const searchQueryPR = ref('')
  const perPagePO = ref(10)
  const totalItemsPO = ref(0)
  const currentPagePO = ref(1)
  const searchQueryPO = ref('')
  const sortBy = ref('material_request_id')
  const sortByPR = ref('purchase_request_id')
  const sortByPO = ref('purchase_order_id')
  const isSortDirDesc = ref(true)
  const isSortDirDescPR = ref(true)
  const isSortDirDescPO = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const startDateFilterPR = ref('')
  const endDateFilterPR = ref('')
  const startDateFilterPO = ref('')
  const endDateFilterPO = ref('')

  const dataMetaVoid = computed(() => {
    const localItemsCount = refFetchVoidMR.value ? refFetchVoidMR.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const dataMetaVoidPR = computed(() => {
    const localItemsCountPR = refFetchVoidPR.value ? refFetchVoidPR.value.localItems.length : 0
    return {
      from: perPagePR.value * (currentPagePR.value - 1) + (localItemsCountPR ? 1 : 0),
      to: perPagePR.value * (currentPagePR.value - 1) + localItemsCountPR,
      of: totalItemsPR.value,
    }
  })

  const dataMetaVoidPO = computed(() => {
    const localItemsCountPO = refFetchVoidPO.value ? refFetchVoidPO.value.localItems.length : 0
    return {
      from: perPagePO.value * (currentPagePO.value - 1) + (localItemsCountPO ? 1 : 0),
      to: perPagePO.value * (currentPagePO.value - 1) + localItemsCountPO,
      of: totalItemsPO.value,
    }
  })

  const refetchData = () => {
    refFetchVoidMR.value.refresh()
  }

  const refetchDataPR = () => {
    refFetchVoidPR.value.refresh()
  }

  const refetchDataPO = () => {
    refFetchVoidPO.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, sortByPR, sortByPO, isSortDirDesc, isSortDirDescPR, isSortDirDescPO, currentPagePR, perPagePR, searchQueryPR, startDateFilter, endDateFilter, startDateFilterPR, endDateFilterPR, currentPagePO, perPagePO, searchQueryPO, startDateFilterPO, endDateFilterPO], () => {
    refetchData()
    refetchDataPR()
    refetchDataPO()
  })

  const fetchVoidMR = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const toVoid = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST}?order_sort=${isSortDirDesc.value ? 'DESC' : 'ASC'}&limit=${perPage.value}&offset=${toVoid}&date_column=material_request_deleted_time&search=${searchQuery.value}&type=void&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&void=true&order_by=${sortBy.value}`, { headers })
      .then(response => {
        console.log(sortBy.value)
        totalItems.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    }
      })
  }

  const fetchVoidPR = (ctx, callback) => {
    const to = perPagePR.value * (currentPagePR.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?order_sort=${isSortDirDescPR.value ? 'DESC' : 'ASC'}&limit=${perPagePR.value}&offset=${to}&date_column=purchase_request_deleted_time&search=${searchQueryPR.value}&date_start=${startDateFilterPR.value}&date_end=${endDateFilterPR.value}&void=true&order_by=${sortByPR.value}`, { headers })
      .then(response => {
        totalItemsPR.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchVoidPO = (ctx, callback) => {
    const to = perPagePO.value * (currentPagePO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?order_sort=${isSortDirDescPO.value ? 'DESC' : 'ASC'}&limit=${perPagePO.value}&offset=${to}&status=void&date_column=purchase_order_deleted_time&date_start=${startDateFilterPO.value}&date_end=${endDateFilterPO.value}&search=${searchQueryPO.value}&order_by=${sortByPO.value}`, { headers })
      .then(response => {
        totalItemsPO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    refFetchVoidMR,
    refFetchVoidPR,
    refFetchVoidPO,
    tableColumns,
    tableColumnsPR,
    tableColumnsPO,
    perPagePR,
    currentPagePR,
    totalItemsPR,
    perPagePO,
    currentPagePO,
    totalItemsPO,
    perPage,
    currentPage,
    totalItems,
    dataMetaVoid,
    dataMetaVoidPR,
    dataMetaVoidPO,
    perPageOptions,
    searchQueryPR,
    searchQueryPO,
    searchQuery,
    sortBy,
    sortByPR,
    sortByPO,
    isSortDirDesc,
    isSortDirDescPR,
    isSortDirDescPO,
    fetchVoidMR,
    fetchVoidPR,
    fetchVoidPO,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    refetchDataPR,
    refetchDataPO,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startDateFilterPR,
    endDateFilterPR,
    startDateFilterPO,
    endDateFilterPO,
  }
}
